import React from "react";
import Base from "../core/Base";
import logo1 from "../assets/logo1.png";
import logo2 from "../assets/logo2.png";
import logo3 from "../assets/logo3.png";
import logo4 from "../assets/logo4.png";
import logo5 from "../assets/logo5.png";
import logo6 from "../assets/logo6.png";
import logo7 from "../assets/logo7.png";
import logo8 from "../assets/logo8.png";
import logo9 from "../assets/logo9.png";
import logo10 from "../assets/logo10.png";
import logo11 from "../assets/logo11.png";
import logo12 from "../assets/logo12.png";
import logo13 from "../assets/logo13.png";
import logo14 from "../assets/logo14.png";
import logo15 from "../assets/logo15.png";
import logo16 from "../assets/logo16.png";
import logo17 from "../assets/logo17.png";
import logo18 from "../assets/logo18.png";
import logo19 from "../assets/logo19.png";
import logo20 from "../assets/logo20.png";
import logo21 from "../assets/logo21.png";
import logo22 from "../assets/logo22.png";
import logo23 from "../assets/logo23.png";
import logo24 from "../assets/logo24.png";
import logo25 from "../assets/logo25.png";

import certificate from "../assets/mSoultions.png";
import { Row, Col } from "react-bootstrap";

const About = () => {
  return (
    <Base>
      <div className="text-center m-4">
        <h1>About Us</h1>
      </div>
      <div className="text-center">
        <img src={certificate} className="certificate" alt="M ~ Solutions" />
      </div>

      <div className="container">
        <h5>
          We rapidly growing software company specializing in the field of
          providing business solutions and services to the wide range of
          commercial sectors. Established in year 2003, M ~ Solutions has
          provided solutions to various establishments including the finance,
          retail, automobile, garment, wholesale sectors.
        </h5>
      </div>

      <h1 className="text-center mt-4">Our clients</h1>
      <div className="container">
        <Row>
          <Col lg={4} sm={6}>
            <img className="customer-logo" src={logo1} alt="logo-img" />
          </Col>
          <Col lg={4} sm={6}>
            <img className="customer-logo" src={logo2} alt="logo-img" />
          </Col>
          <Col lg={4} sm={6}>
            <img className="customer-logo" src={logo3} alt="logo-img" />
          </Col>
          <Col lg={4} sm={6}>
            <img className="customer-logo" src={logo4} alt="logo-img" />
          </Col>
          <Col sm={6} lg={4}>
            <img className="customer-logo" src={logo5} alt="logo-img" />
          </Col>
          <Col sm={6} lg={4}>
            <img className="customer-logo" src={logo6} alt="logo-img" />
          </Col>
          <Col sm={6} lg={4}>
            <img className="customer-logo" src={logo7} alt="logo-img" />
          </Col>
          <Col sm={6} lg={4}>
            <img className="customer-logo" src={logo8} alt="logo-img" />
          </Col>
          <Col sm={6} lg={4}>
            <img className="customer-logo" src={logo9} alt="logo-img" />
          </Col>
          <Col sm={6} lg={4}>
            <img className="customer-logo" src={logo10} alt="logo-img" />
          </Col>
          <Col sm={6} lg={4}>
            <img className="customer-logo" src={logo11} alt="logo-img" />
          </Col>
          <Col sm={6} lg={4}>
            <img className="customer-logo" src={logo12} alt="logo-img" />
          </Col>
          <Col sm={6} lg={4}>
            <img className="customer-logo" src={logo13} alt="logo-img" />
          </Col>
          <Col sm={6} lg={4}>
            <img className="customer-logo" src={logo14} alt="logo-img" />
          </Col>
          <Col sm={6} lg={4}>
            <img className="customer-logo" src={logo15} alt="logo-img" />
          </Col>
          <Col sm={6} lg={4}>
            <img className="customer-logo" src={logo16} alt="logo-img" />
          </Col>
          <Col sm={6} lg={4}>
            <img className="customer-logo" src={logo17} alt="logo-img" />
          </Col>
          <Col sm={6} lg={4}>
            <img className="customer-logo" src={logo18} alt="logo-img" />
          </Col>
          <Col sm={6} lg={4}>
            <img className="customer-logo" src={logo19} alt="logo-img" />
          </Col>{" "}
          <Col sm={6} lg={4}>
            <img className="customer-logo" src={logo20} alt="logo-img" />
          </Col>{" "}
          <Col sm={6} lg={4}>
            <img className="customer-logo" src={logo21} alt="logo-img" />
          </Col>{" "}
          <Col sm={6} lg={4}>
            <img className="customer-logo" src={logo22} alt="logo-img" />
          </Col>{" "}
          <Col sm={6} lg={4}>
            <img className="customer-logo" src={logo23} alt="logo-img" />
          </Col>{" "}
          <Col sm={6} lg={4}>
            <img className="customer-logo" src={logo24} alt="logo-img" />
          </Col>
        </Row>
        <Col sm={6} lg={{ span: 4, offset: 4 }}>
          <img className="customer-logo" src={logo25} alt="logo-img" />
        </Col>
      </div>
    </Base>
  );
};

export default About;
